<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="addOrUpdate" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formTitle }}</h5>
          <button
            @click="close"
            :class="{ 'is-busy': isLoading }"
            class="btn-close"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <!-- FIELD: Name -->
          <div class="form-floating">
            <input
              v-model.trim="form.name"
              ref="name"
              type="text"
              class="form-control"
              id="fname"
              placeholder="Example: Deadlift 1RM"
            >
            <label for="fname">Name<sup class="text-danger">*</sup></label>
            <div class="form-text">Example: Deadlift 1RM. Minimum 3 characters.</div>
          </div>

          <!-- FIELD: Unit -->
          <div class="form-floating mt-3">
            <input
              v-model.trim="form.unit"
              ref="unit"
              type="text"
              class="form-control"
              id="funit"
              placeholder="Example: kg, lbs, %"
            >
            <label for="fname">Unit<sup class="text-danger">*</sup></label>
            <div class="form-text">Example: kg, lbs, %</div>
          </div>

          <!-- FIELD: Error -->
          <ErrorMessage v-if="error" :error="error" class="m-0 mt-3"></ErrorMessage>
        </div>
        <div class="modal-footer">
          <SubmitButton
            :text="buttonTitle"
            :textBusy="'Please wait...'"
            :isLoading="isLoading"
            :disabled="isLoading || isSubmitDisabled"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { deepCopy } from '@/core/util';

const formDefaultData = () => ({
  metricId: null,
  metricGroupId: null,
  authorId: '',
  name: '',
  unit: '',
});

export default {
  name: 'MetricModal',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    metric: {
      type: Object,
    },
    metricGroup: {
      type: Object,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    formTitle() {
      return this.isEditMode ? 'Edit metric' : 'New metric';
    },
    buttonTitle() {
      return this.isEditMode ? 'Update' : 'Submit';
    },
    isEditMode() {
      return !!this.metric;
    },
    isSubmitDisabled() {
      const { name, unit } = this.form;
      return name.length < 3 || !unit.length;
    },
  },
  methods: {
    close(forced = false) {
      if (this.isLoading && forced !== true) return;
      this.$emit('close');
    },
    async addOrUpdate() {
      this.isLoading = true;
      this.error = null;
      try {
        const payload = { ...this.form };
        await this.$store.dispatch('metric/addItem', payload);
        this.close(true);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      form: null,
    };
  },
  mounted() {
    this.$refs.name.focus();
  },
  created() {
    if (this.isEditMode) {
      this.form = deepCopy(this.metric);
    } else {
      this.form = formDefaultData();
      this.form.metricGroupId = this.metricGroup ? this.metricGroup.metricGroupId : null;
      this.form.authorId = this.authUser.userId;
    }
  },
};
</script>
